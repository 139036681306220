import { getOMFR } from "../utils";

export default class APIService {
    /**
     * Return alerts from OM API
     * @returns Promise<any>
     */
    static fetchAlerts = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${getOMFR()}/api/1.2/promoevents`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }

    /**
     * Return player informations from OM API
     * @returns Promise<any>
     */
    static fetchPlayer = async (playerId: String): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${getOMFR()}/api/1.2/promoevents`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }

    /**
     * Return next match informations from OM API
     * @returns Promise<any>
     */
    static fetchNextMatch = async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            fetch(`${getOMFR()}/api/1.2/match/next`)
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                }).catch((error: any) => {
                    reject(error);
                });
        })
    }

    /**
     * Return personnalized shirt image with provieded name input
     * @param name name to add to shirt
     * @returns 
     */
    static fetchShirt = async (name: String): Promise<any> => {
        return new Promise((resolve, reject) => {
        })
    }
}